export const FaqList = [
  {
    id: "headingOne",
    controls: "collapseOne",
    expanded: true,
    btnClass: "",
    contentClass: "show",
    title: "What services do you offer?",
    details: `Fix Right offers a wide range of handyman services for both residential and commercial clients. Our services include plumbing repairs, electrical work, carpentry, drywall repair, painting, flooring installation, and general home maintenance and repairs.`,
  },
  {
    id: "headingTwo",
    controls: "collapseTwo",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "Are your handymen licensed and insured?",
    details: `Yes, all of our handymen are licensed and insured for your protection. We carry general liability insurance and worker's compensation insurance to ensure that you are covered in case of any accidents or damages.`,
  },
  {
    id: "headingThree",
    controls: "collapseThree",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "How do I schedule a service appointment?",
    details: `You can schedule a service appointment by calling our office or filling out the online form on our website. One of our friendly customer service representatives will work with you to schedule a convenient time for one of our handymen to come to your home or business.`,
  },
  {
    id: "headingFour",
    controls: "collapseFour",
    expanded: false,
    btnClass: "collapsed",
    contentClass: "",
    title: "Do you offer any warranties or guarantees?",
    details: `Yes, we offer a satisfaction guarantee on all of our work. If you're not completely satisfied with the job, we'll make it right. We also provide warranties on certain services, such as a one-year warranty on plumbing and electrical work.`,
  },
];