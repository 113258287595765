export const TestimonialItemsArray = [
  {
    src: "/img/images/avatar_img01.png",
    title: "Ahmad Razak",
    designation: "Homeowner",
    desc: `I am very pleased with FixRight. The team was prompt and fixed my leaking pipes well. Their professionalism and attention to detail are commendable. Highly recommended.`,
  },
  {
    src: "/img/images/avatar_img02.png",
    title: "Siti Aminah",
    designation: "Restaurant Owner",
    desc: `FixRight's electrical services are outstanding. They resolved our wiring issues quickly, ensuring everything is safe. A reliable service provider I trust. Very satisfied.`,
  },
  {
    src: "/img/images/avatar_img03.png",
    title: "Vim Yei",
    designation: "Office Manager",
    desc: `FixRight's team did a superb job renovating our office. They were professional and efficient. The results exceeded expectations. We will definitely hire them again.`,
  },
  {
    src: "/img/images/avatar_img04.png",
    title: "Nurul Huda",
    designation: "Retail Store Owner",
    desc: `FixRight provided an excellent service! They fixed all our plumbing issues quickly. The team was courteous and ensured everything was perfect. Very impressed with them.`,
  },
];
