import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";

const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area footer-bg">
        <div className="footer-top">
          <div className="container">
            <div className="footer-logo-area">
              <div className="row align-items-center">
                <div className="col-md-4">
                  <div className="logo">
                    <Link to="/">
                      <img src="/img/logo/w_logo.svg" alt="" />
                    </Link>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="footer-social-menu">
                    <ul className="list-wrap">
                      <li>
                        <a href="#">Facebook</a>
                      </li>
                      <li>
                        <a href="#">Twitter</a>
                      </li>
                      <li>
                        <a href="#">LinkedIn</a>
                      </li>
                      <li>
                        <a href="#">Instagram</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <div className="footer-content">
                    <p>Cheras, 56000 Kuala Lumpur, Federal Territory of Kuala Lumpur, Malaysia</p>
                    <div className="footer-contact">
                      <ul className="list-wrap">
                        <li className="phone-addess">
                          <i className="fas fa-phone-alt"></i>
                          <a href="tel:+601121035384">+60 11210 35384</a>
                        </li>
                        <li className="email-addess">
                          <a href="mailto:mhmdtahir144@gmail.com">mhmdtahir144@gmail.com</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Links</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/about">About</Link>
                      </li>
                      <li>
                        <Link to="/services">What We Do</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6">
                <div className="footer-widget">
                  <div className="fw-title">
                    <h4 className="title">Our Services</h4>
                  </div>
                  <div className="fw-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="/services">Renovation</Link>
                      </li>
                      <li>
                        <Link to="/services">Electrition</Link>
                      </li>
                      <li>
                        <Link to="/services">Plumbing</Link>
                      </li>
                      <li>
                        <Link to="/services">Painting</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* footer bottom */}
        <FooterBottom />
      </div>
    </footer>
  );
};

export default FooterOne;