import React from "react";

const ContactMapArea = () => {
  return (
    <div id="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3983.954265281011!2d101.7259!3d3.1068!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc35e14ef16f6d%3A0xff7e4ae6fc0089ff!2sCheras%2C%2056000%20Kuala%20Lumpur%2C%20Federal%20Territory%20of%20Kuala%20Lumpur%2C%20Malaysia!5e0!3m2!1sen!2s!4v1717360100488!5m2!1sen!2s"
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default ContactMapArea;
