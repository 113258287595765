import React from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Iframe from "react-iframe";
import { FaWhatsapp } from 'react-icons/fa';

const IntroductionAreaOne = () => {
  return (
    <section id="intro" className="introduction-area pb-130">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-9">
            <div className="introduction-img-wrap">
              <img src="/img/images/introduction_img01.png" alt="" />
              <img
                src="/img/images/introduction_img02.png"
                data-aos="fade-right"
                alt=""
              />

              {/* <Popup
                trigger={
                  <div className="play-btn">
                    <a
                      href="#intro"
                      className="popup-video"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                }
                position=""
                modal={true}
              >
                <Iframe
                  url="https://www.youtube.com/embed/XMWYZ-uZjnQ"
                  width="100%"
                  height="350px"
                  id=""
                  className=""
                  display="block"
                  position="relative"
                />
              </Popup> */}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="introduction-content">
              <div className="section-title-two mb-20 tg-heading-subheading animation-style2">
                <span className="sub-title">Our Introduction</span>
                <h2 className="title tg-element-title">
                  Best Handyman Services<br />
                  In Malaysia
                </h2>
              </div>

              <p className="info-one">
              We offer comprehensive handyman services tailored to meet all your home improvement needs. 
              Our skilled team is committed to providing exceptional workmanship and reliable service.
              </p>
              <p>
                From renovations to plumbing, electrical work, and more, 
                our expertise ensures every project is handled with precision and care. 
                Trust us to deliver top-notch results that enhance the comfort and functionality of your home.
              </p>

              <div className="introduction-list">
                <ul className="list-wrap">
                  <li>
                    <i className="fas fa-check-circle"></i>We are Committed
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Highly Rated Renovation
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Insured & Bonded
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Trusted Professionals
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Residential Renovation
                  </li>
                  <li>
                    <i className="fas fa-check-circle"></i>Commercial Renovation
                  </li>
                </ul>
              </div>

              <div className="introduction-bottom">
              <Link to="https://wa.me/601121035384" className="btn" 
              target="_blank" rel="noopener noreferrer">
                <FaWhatsapp style={{ marginRight: '8px', fontSize: '24px' }} />
                WhatsApp
              </Link>

                <span className="call-now">
                  <i className="fas fa-phone-alt"></i>
                  <a href="tel:+60 11-2103 5384">+60 11-2103 5384</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroductionAreaOne;
